<div class="row">
  <div class="col-10 offset-1 col-md-6 offset-md-3 col-lg-6 offset-lg-3">
    <form class="card" onsubmit="return false;">
      <h3 class="title heading-m m-semibold">{{ 'auth.login.buttonLabel' | translate }}</h3>

      <cc-input-field
        name="loginName"
        label="{{ 'auth.login.usernameLabelAndPlaceholder' | translate }}"
        placeholder="{{ 'auth.login.usernameLabelAndPlaceholder' | translate }}"
        [(value)]="username"
        [autoFocus]="true"
      ></cc-input-field>

      <cc-input-field
        name="loginPassword"
        label="{{ 'auth.login.passwordLabel' | translate }}"
        placeholder="{{ 'auth.login.passwordLabel' | translate }}"
        [(value)]="password"
        autocomplete="off"
        type="{{ passwordVisible ? 'text' : 'password' }}"
      >
        <button class="btn-plain additionl-icon" (click)="passwordVisible = !passwordVisible">
          @if (passwordVisible) {
            <app-icon-eye-hidden-outline></app-icon-eye-hidden-outline>
          } @else {
            <app-icon-eye-show-outline></app-icon-eye-show-outline>
          }
        </button>
      </cc-input-field>

      <span class="text-xs">
        {{ 'auth.forgotPassword.text' | translate }}
        <a [routerLink]="['/forgot-password']" class="text-xs m-bold">
          {{ 'auth.login.resetPasswordLink' | translate }} </a
        >.
      </span>

      <button name="loginSubmit" data-testid="btn-send" class="btn-primary width-100" (click)="handleLogin()">
        {{ 'auth.login.buttonLabel' | translate }}
      </button>
    </form>
  </div>
</div>
